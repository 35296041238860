var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.title,
        width: 600,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭"
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel }
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form-model",
            {
              ref: "form",
              attrs: { model: _vm.model, rules: _vm.validatorRules }
            },
            [
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "dictName",
                    required: "",
                    label: "字典名称"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入字典名称" },
                    model: {
                      value: _vm.model.dictName,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "dictName", $$v)
                      },
                      expression: "model.dictName"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    prop: "dictCode",
                    required: "",
                    label: "字典编码"
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入字典编码" },
                    model: {
                      value: _vm.model.dictCode,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "dictCode", $$v)
                      },
                      expression: "model.dictCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "描述"
                  }
                },
                [
                  _c("a-input", {
                    model: {
                      value: _vm.model.description,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "description", $$v)
                      },
                      expression: "model.description"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }