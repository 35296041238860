var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "div",
        {
          staticClass: "table-operator",
          staticStyle: { margin: "-25px 0px 10px 0px" },
          attrs: { md: 24, sm: 24 }
        },
        [
          _c(
            "a-button",
            {
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v("新增")]
          ),
          _vm.selectedRowKeys.length > 0
            ? _c(
                "a-dropdown",
                [
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      _c(
                        "a-menu-item",
                        { key: "1", on: { click: _vm.batchDel } },
                        [
                          _c("a-icon", { attrs: { type: "delete" } }),
                          _vm._v("\n          删除\n        ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    { staticStyle: { "margin-left": "8px" } },
                    [
                      _vm._v(" 批量操作\n        "),
                      _c("a-icon", { attrs: { type: "down" } })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass: "ant-alert ant-alert-info",
              staticStyle: { "margin-bottom": "16px" }
            },
            [
              _c("i", {
                staticClass: "anticon anticon-info-circle ant-alert-icon"
              }),
              _vm._v(" 已选择 "),
              _c("a", { staticStyle: { "font-weight": "600" } }, [
                _vm._v(_vm._s(_vm.selectedRowKeys.length))
              ]),
              _vm._v("项\n      "),
              _c(
                "a",
                {
                  staticStyle: { "margin-left": "24px" },
                  on: { click: _vm.onClearSelected }
                },
                [_vm._v("清空")]
              )
            ]
          ),
          _c("a-table", {
            ref: "table",
            attrs: {
              size: "middle",
              bordered: "",
              rowKey: "id",
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              pagination: _vm.ipagination,
              loading: _vm.loading,
              rowSelection: {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange
              }
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function(text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.handleEdit(record)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a-dropdown",
                        [
                          _c(
                            "a",
                            { staticClass: "ant-dropdown-link" },
                            [
                              _vm._v("\n            更多 "),
                              _c("a-icon", { attrs: { type: "down" } })
                            ],
                            1
                          ),
                          _c(
                            "a-menu",
                            { attrs: { slot: "overlay" }, slot: "overlay" },
                            [
                              _c("a-menu-item", [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "javascript:;" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleDetail(record)
                                      }
                                    }
                                  },
                                  [_vm._v("详情")]
                                )
                              ]),
                              _c(
                                "a-menu-item",
                                [
                                  _c(
                                    "a-popconfirm",
                                    {
                                      attrs: { title: "确定删除吗?" },
                                      on: {
                                        confirm: function() {
                                          return _vm.handleDelete(record.id)
                                        }
                                      }
                                    },
                                    [_c("a", [_vm._v("删除")])]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c("jeecgOrderCustomer-modal", {
        ref: "modalForm",
        on: { ok: _vm.modalFormOk }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }