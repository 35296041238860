var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        width: 900,
        keyboard: false,
        closable: false,
        centered: true,
        maskClosable: false,
        mask: false,
        okText: "确认",
        cancelText: "取消"
      },
      on: { ok: _vm.ok, cancel: _vm.cancel },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "a-tabs",
        [
          _c("a-tab-pane", { key: "1", attrs: { tab: "方向性图标" } }, [
            _c(
              "ul",
              _vm._l(_vm.icons.directionIcons, function(icon) {
                return _c(
                  "li",
                  { key: icon },
                  [
                    _c("a-icon", {
                      class: { active: _vm.activeIndex === icon },
                      attrs: { type: icon, title: icon },
                      on: {
                        click: function($event) {
                          return _vm.chooseIcon(icon)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ]),
          _c("a-tab-pane", { key: "2", attrs: { tab: "指示性图标" } }, [
            _c(
              "ul",
              _vm._l(_vm.icons.suggestionIcons, function(icon) {
                return _c(
                  "li",
                  { key: icon },
                  [
                    _c("a-icon", {
                      class: { active: _vm.activeIndex === icon },
                      attrs: { type: icon, title: icon },
                      on: {
                        click: function($event) {
                          return _vm.chooseIcon(icon)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ]),
          _c("a-tab-pane", { key: "3", attrs: { tab: "编辑类图标" } }, [
            _c(
              "ul",
              _vm._l(_vm.icons.editIcons, function(icon) {
                return _c(
                  "li",
                  { key: icon },
                  [
                    _c("a-icon", {
                      class: { active: _vm.activeIndex === icon },
                      attrs: { type: icon, title: icon },
                      on: {
                        click: function($event) {
                          return _vm.chooseIcon(icon)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ]),
          _c("a-tab-pane", { key: "4", attrs: { tab: "数据类图标" } }, [
            _c(
              "ul",
              _vm._l(_vm.icons.dataIcons, function(icon) {
                return _c(
                  "li",
                  { key: icon },
                  [
                    _c("a-icon", {
                      class: { active: _vm.activeIndex === icon },
                      attrs: { type: icon, title: icon },
                      on: {
                        click: function($event) {
                          return _vm.chooseIcon(icon)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ]),
          _c("a-tab-pane", { key: "5", attrs: { tab: "网站通用图标" } }, [
            _c(
              "ul",
              _vm._l(_vm.icons.webIcons, function(icon) {
                return _c(
                  "li",
                  { key: icon },
                  [
                    _c("a-icon", {
                      class: { active: _vm.activeIndex === icon },
                      attrs: { type: icon, title: icon },
                      on: {
                        click: function($event) {
                          return _vm.chooseIcon(icon)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ]),
          _c("a-tab-pane", { key: "6", attrs: { tab: "品牌和标识" } }, [
            _c(
              "ul",
              _vm._l(_vm.icons.logoIcons, function(icon) {
                return _c(
                  "li",
                  { key: icon },
                  [
                    _c("a-icon", {
                      class: { active: _vm.activeIndex === icon },
                      attrs: { type: icon, title: icon },
                      on: {
                        click: function($event) {
                          return _vm.chooseIcon(icon)
                        }
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }