import { render, staticRenderFns } from "./Step2.vue?vue&type=template&id=734e6070&scoped=true&"
import script from "./Step2.vue?vue&type=script&lang=js&"
export * from "./Step2.vue?vue&type=script&lang=js&"
import style0 from "./Step2.vue?vue&type=style&index=0&id=734e6070&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "734e6070",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/admin/ylpp-admin/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('734e6070')) {
      api.createRecord('734e6070', component.options)
    } else {
      api.reload('734e6070', component.options)
    }
    module.hot.accept("./Step2.vue?vue&type=template&id=734e6070&scoped=true&", function () {
      api.rerender('734e6070', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/user/alteration/Step2.vue"
export default component.exports