var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { bordered: false } },
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            [
              _c(
                "a-switch",
                _vm._b(
                  {
                    model: {
                      value: _vm.pageSwitch,
                      callback: function($$v) {
                        _vm.pageSwitch = $$v
                      },
                      expression: "pageSwitch"
                    }
                  },
                  "a-switch",
                  _vm.pageSwitchProps,
                  false
                )
              )
            ],
            1
          ),
          _c(
            "a-col",
            [
              _c(
                "a-table",
                _vm._b(
                  {
                    staticStyle: { "margin-top": "20px" },
                    on: { change: _vm.handleTableChange }
                  },
                  "a-table",
                  _vm.tableProps,
                  false
                )
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }