<template>
  <div>
    <a-form-model ref="form" :model="model" :rules="validatorRules" class="password-retrieval-form" :autoLink="false">
      <!-- <a-form-model-item label="用户账号" required  :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" prop="userName">
        <a-input v-model="model.userName" placeholder="请输入用户账号"></a-input>
      </a-form-model-item> -->
      <a-form-model-item label="教师姓名" required :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" prop="name">
        <a-input v-model="model.name" placeholder="请输入教师姓名"></a-input>
      </a-form-model-item>
      <a-form-model-item label="教师编码" :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }">
        <a-input v-model="model.code" placeholder="请输入教师编码"></a-input>
      </a-form-model-item>
      <a-form-model-item
        class="selectBox"
        label="学校名称"
        :labelCol="{ span: 5 }"
        :wrapperCol="{ span: 19 }"
        prop="schoolIdCode"
      >
        <a-select
          v-model:value="model.schoolIdCode"
          default-active-first-option="false"
          :filter-option="false"
          :not-found-content="null"
          :options="schoolData"
          :show-arrow="false"
          show-search
          show-placeholder
          @search="handleSearch"
          @change="roomChange"
          placeholder="请输入学校名称进行搜索"
        >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="院系" prop="departmentId" required :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }">
        <a-select v-model="model.departmentId" @change="baseMajorAll">
          <a-select-option v-for="(item, index) in departmentList" :key="index" :value="item.id">{{
            item.name
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="班级名称" required :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" prop="classIds">
        <!-- <a-cascader
          v-model:value="model.classIds"
          :options="options"
          :load-data="loadData"
          placeholder="请选择班级名称"
          change-on-select
          @change="onChange"
        /> -->
        <!-- <a-tree-select
          v-model:value="model.classIds"
          tree-data-simple-mode
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="options"
          placeholder="请选择班级"
        /> -->
        <a-tree-select
          v-model:value="model.classIds"
          style="width: 100%"
          :tree-data="options"
          tree-checkable
          allow-clear
          search-placeholder="Please select"
        />
      </a-form-model-item>
      <a-form-model-item label="组织" prop="relTenantIds" required :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }">
        <a-select mode="multiple" v-model="model.relTenantIds">
          <a-select-option v-for="(item, index) in tenantList" :key="index" :value="item.id">{{
            item.name
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="电子邮箱" required :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" prop="email">
        <a-input v-model="model.email" autocomplete="false" placeholder="请输入电子邮箱"></a-input>
      </a-form-model-item>
      <a-form-model-item label="性别" required :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" prop="gender">
        <j-dict-select-tag type="radio" v-model="model.gender" dictCode="sex" placeholder="请选择性别" />
      </a-form-model-item>

      <a-form-model-item
        prop="password"
        :labelCol="{ span: 5 }"
        :wrapperCol="{ span: 19 }"
        label="新密码"
        class="stepFormText"
      >
        <a-input v-model="model.password" type="password" autocomplete="new-password" />
      </a-form-model-item>

      <a-form-model-item
        prop="confirmPassword"
        :labelCol="{ span: 5 }"
        :wrapperCol="{ span: 19 }"
        label="确认密码"
        class="stepFormText"
      >
        <a-input v-model="model.confirmPassword" type="password" autocomplete="false" />
      </a-form-model-item>

      <a-form-model-item label="手机" required prop="phone" :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }">
        <a-input v-model="model.phone" type="text" autocomplete="false" placeholder="请输入手机号">
          <a-icon slot="prefix" type="phone" :style="{ color: 'rgba(0,0,0,.25)' }" />
        </a-input>
      </a-form-model-item>
      <a-form-model-item
        v-if="show"
        required
        prop="captcha"
        label="验证码"
        :labelCol="{ span: 5 }"
        :wrapperCol="{ span: 19 }"
      >
        <a-row :gutter="16">
          <a-col class="gutter-row" :span="12">
            <a-input @change="captchaChange" v-model="model.captcha" type="text" placeholder="手机短信验证码">
              <a-icon slot="prefix" type="code" :style="{ color: 'rgba(0,0,0,.25)' }" />
            </a-input>
          </a-col>
          <a-col class="gutter-row" :span="8">
            <a-button
              tabindex="-1"
              size="default"
              :disabled="state.smsSendBtn"
              @click.stop.prevent="getCaptcha"
              v-text="(!state.smsSendBtn && '获取验证码') || state.time + ' s'"
            ></a-button>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ span: 19, offset: 5 }">
        <router-link style="float: left; line-height: 40px" :to="{ name: 'login' }">使用已有账户登录</router-link>
        <a-button type="primary" @click="nextStep" style="margin-left: 20px">下一步</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { httpAction, getAction, postAction } from '@/api/manage'
export default {
  name: 'Step2',
  props: ['userList'],
  data() {
    return {
      tenantList: [],
      options: [],
      model: { schoolIdCode: '', schoolName: '' },
      loading: false,
      // accountName: this.userList.username,
      dropList: '0',
      captcha: '',
      show: true,
      state: {
        time: 60,
        smsSendBtn: false,
      },
      departmentList: [],
      mojorList: [],
      formLogin: {
        captcha: '',
        mobile: '',
      },
      schoolData: [],
      url: {
        querySchool: '/api/university/list',
        baseDepartmentAll: 'ylpp/baseDepartment/all',
        baseMajorAll: 'ylpp/baseMajor/linkage',
        baseClassAll: 'ylpp/tbClass/pulldown/major',
        tenantAll: 'sys/tenant/all',
        alldata: 'ylpp/baseMajor/alldata',
      },
      validatorRules: {
        captcha: [{ required: true, message: '请输入短信验证码!' }],
        password: [
          {
            required: true,
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[^]{8,16}$/g,
            message: '密码由8-16位大小写字母、数字组成!!',
          },
        ],
        confirmPassword: [{ required: true, message: '密码不能为空!' }, { validator: this.handlePasswordCheck }],
        code: [{ required: true, message: '请输入教师编码!' }],
        classIds: [{ required: true, message: '请选择班级!' }],
        relTenantIds: [{ required: true, message: '请选择组织!' }],
        schoolIdCode: [{ required: true, message: '请选择学校名称!' }],
        name: [{ required: true, message: '请输入教师姓名!' }],
        phone: [
          { required: true, message: '请输入手机号!' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号码!' },
        ],
        email: [
          { required: true, message: '请输入电子邮箱!' },
          { pattern: /^([\w]+\.*)([\w]+)@[\w]+\.\w{3}(\.\w{2}|)$/, message: '请输入正确的电子邮箱!' },
        ],
        gender: [{ required: true, message: '请输入性别!' }],
      },
    }
  },
  computed: {},
  methods: {
    nextStep() {
      let that = this
      that.loading = true
      this.$refs['form'].validate((success) => {
        if (success == true) {
          // var arr = []
          // arr.push(this.model.classIds[2])
          // this.model.classIds = arr
          if (this.model.classIds) {
            postAction('ylpp/teacher/register', this.model).then((res) => {
              if (res.success) {
                let userList = {
                  username: this.model.name,
                  phone: this.model.phone,
                  smscode: res.result.smscode,
                }
                setTimeout(function () {
                  that.$emit('nextStep', this.model)
                }, 0)
              } else {
                this.cmsFailed(res.message)
              }
            })
          } else {
            this.cmsFailed('请选择班级')
          }
        }
      })
    },
    getCaptcha(e) {
      e.preventDefault()
      const that = this
      that.$refs['form'].validateField('phone', (err) => {
        console.log(err)
        if (!err) {
          that.state.smsSendBtn = true
          let interval = window.setInterval(() => {
            if (that.state.time-- <= 0) {
              that.state.time = 60
              that.state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)
          const hide = that.$message.loading('验证码发送中..', 0)
          let smsParams = {
            model: that.model.phone,
            type: '1',
            userType: 1,
          }
          postAction('/sys/sms/code', smsParams).then((res) => {
            if (!res.success) {
              setTimeout(hide, 1)
              that.cmsFailed(res.message)
            }
            setTimeout(hide, 500)
          })
        } else {
          that.cmsFailed(err)
        }
      })
    },
    handlePasswordCheck(rule, value, callback) {
      let password = this.model['password']
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error('两次密码不一致'))
      }
      callback()
    },
    async handleSearch(val) {
      this.schoolData = []
      let params = { name: val, pageNo: 1, pageSize: 100 }
      getAction(this.url.querySchool, params).then((res) => {
        if (res.success) {
          res.result.records.forEach((item) => {
            this.schoolData.push({
              //循环，把值装进去
              value: item.code,
              label: item.name,
            })
          })
        }
      })
    },
    cmsFailed(err) {
      this.$notification['error']({
        message: '验证错误',
        description: err,
        duration: 4,
      })
    },
    handleChangeSelect(value) {
      var that = this
      if (value == 0) {
        that.dropList = '0'
        that.show = true
      } else {
        that.dropList = '1'
        that.show = false
      }
    },
    validatePhone(rule, value, callback) {
      if (value) {
        var myreg = /^[1][3,4,5,7,8][0-9]{9}$/
        if (!myreg.test(value)) {
          callback('请输入正确的手机号')
        } else {
          callback()
        }
      } else {
        callback()
      }
    },
    //手机号改变事件
    captchaChange(val) {
      this.$refs['form'].validateField('captcha')
    },
    onChange(value) {},
    roomChange(val) {
      let that = this
      this.schoolData.forEach((item) => {
        if (val == item.value) {
          this.model.schoolName = item.label
          that.showSearchSchool = false
          that.baseDepartmentAll()
          this.tenantAll()
        }
      })
    },
    baseDepartmentAll() {
      this.departmentList = []
      let params = {
        schoolCode: this.model.schoolIdCode,
      }
      getAction(this.url.baseDepartmentAll, params).then((res) => {
        if (res.success) {
          console.log(res.result)
          this.departmentList = res.result
          console.log(this.departmentList)
          console.log('====')
          //isLeaf
          // this.options = res.result
          // this.options.forEach((item) => {
          //   item.label = item.name
          //   item.value = item.id
          //   item.isLeaf = false
          //   item.level = 1
          // })
        }
      })
    },
    tenantAll() {
      this.tenantList = []
      let params = {
        schoolCode: this.model.schoolIdCode,
      }
      getAction(this.url.tenantAll, params).then((res) => {
        if (res.success) {
          console.log(res.result.records)
          this.tenantList = res.result
          //this.departmentList=res.result.records
          //isLeaf
          // this.options = res.result.records
          // this.options.forEach((item) => {
          //   item.label = item.name
          //   item.value = item.id
          //   item.isLeaf = false
          //   item.level = 1
          // })
          console.log(this.options)
        }
      })
    },
    onLoadData(treeNode) {
      console.log(23456789)
      //console.log(treeNode.dataRef)
      //console.log(treeNode)
      //const targetOption = treeNode[treeNode.length - 1]
      //targetOption.loading = true
      // let option = treeNode.dataRef.value
      //console.log(option)
      //this.baseClassAll(option)
      //.then((res) => {
      //console.log(res)
      //  treeNode.value = treeNode.value.concat([res[0]])
      //     // res.forEach((item) => {
      //     //   // item.id = item.id;
      //     //   // item.pId = 0;
      //     //   // item.value = item.id;
      //     //   // item.title = item.name;
      //     //   // item.isLeaf = true;
      //     //   setTimeout(() => {
      //     //     //treeNode.value = treeNode.value.concat([item])
      //     //     console.log(treeNode)
      //     //   }, 300)
      //     // })
      //     //resolve()
      //     // targetOption.loading = false
      //     // targetOption.children = res
      //     // this.options = [...this.options]
      //  })
      //  .catch((err) => {
      //    console.log(err)
      //    targetOption.loading = false
      //  })
      //this.
    },
    loadData(selectedOptions) {
      console.log(selectedOptions)
      console.log(selectedOptions[selectedOptions.length - 1])
      let option = selectedOptions[selectedOptions.length - 1]
      console.log(option.id)
      console.log(option.name)
      console.log(option.level)
      const targetOption = selectedOptions[selectedOptions.length - 1]
      targetOption.loading = true
      if (option.level == 1) {
        this.baseMajorAll(option)
          .then((res) => {
            console.log(res)
            targetOption.loading = false
            targetOption.children = res
            this.options = [...this.options]
          })
          .catch((err) => {
            console.log(err)
            //this.$Message.error(err)
            targetOption.loading = false
          })
      } else if (option.level == 2) {
      }
      // // load options lazily
      // setTimeout(() => {
      //   targetOption.loading = false;
      //   targetOption.children = [
      //     {
      //       label: `${targetOption.label} Dynamic 1`,
      //       value: 'dynamic1',
      //     },
      //     {
      //       label: `${targetOption.label} Dynamic 2`,
      //       value: 'dynamic2',
      //     },
      //   ];
      //   console.log(this.options)
      //   this.options = [...this.options];
      //   console.log(this.options)
      // }, 1000);
    },
    baseClassAll(id) {
      console.log(id)
      let that = this
      return new Promise(function (resolve, reject) {
        let params = {
          majorId: id,
        }
        getAction(that.url.baseClassAll, params).then((res) => {
          if (res.success) {
            res.result.forEach((item) => {
              item.id = item.id
              item.pId = id
              item.value = item.id
              item.title = item.name
              item.isLeaf = true
            })
            resolve(res.result)
          } else {
            resolve(res.message)
          }
        })
      })
    },
    baseMajorAll(option) {
      let that = this
      let params = {
        departmentId: this.model.departmentId,
      }
      getAction(that.url.alldata, params).then((res) => {
        console.log(res)
        if (res.success) {
          this.options = res.result
          this.options.forEach((item) => {
            item.value = item.id;
            item.title = item.name;
            item.key = item.id;
            item.disabled = true;
            if (item.children) {
              item.children.forEach((item1) => {
                item1.value = item1.id
                item1.title = item1.name
                item1.key = item1.id
              })
            }
          })
          //this.mojorList = res.result;
          //resolve(res.result)
        }
      })
    },
    handleInput(e) {
      console.log(e)
    },
  },
}
</script>

<style lang="less" scoped>
.stepFormText {
  margin-bottom: 24px;
}

.ant-form-item-label,
.ant-form-item-control {
  line-height: 22px;
}

.getCaptcha {
  display: block;
  width: 100%;
  height: 40px;
}
</style>
